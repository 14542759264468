import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';


@Injectable({ providedIn: 'root' })
export class LoginGuard {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {  }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUser();
        if (currentUser) {
            // logged in so return true
            this.router.navigate(['flight/flight-search']);
            return false;
        }
        return true;
    }
}
