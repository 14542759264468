import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HeaderService } from './core/services/header.service';
import { DataShareService } from './core/services/datashare.sevice';
import { HolidayService } from './core/services/holiday.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  isBorder: boolean = false;

  constructor(
    private headerService: HeaderService,
    private dataShareService: DataShareService,
    private holidayService: HolidayService
  ) {}
  ngOnInit() {
    this.getAllCountryCodes();
    this.getLookUpCountries();
    this.subscribeBorderSubject();
  }

  getAllCountryCodes() {
    this.headerService.getAllCountries().subscribe({
      next: (response: any) => {
        this.dataShareService.setCountryCodes(response.length ? response : []);
      },
    });
  }

  getLookUpCountries() {
    this.holidayService.getAllCountry().subscribe({
      next: (res: any) => {
        this.dataShareService.setAllCountry(res);
      },
    });
  }

  subscribeBorderSubject() {
    this.dataShareService.borderSetSubject$.subscribe((res) => {
      this.isBorder = res;
    });
  }
}
