import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'Dashboard',
    icon: 'uil-home-alt',
    link: '/',
  },
  {
    id: 2,
    label: 'Flight',
    icon: 'uil-plane',
    subItems: [
      {
        id: 21,
        label: 'Search',
        link: '/flight/flight-search',
        parentId: 2,
      },
      {
        id: 22,
        // label: 'Queas',
        label: 'Queues',
        parentId: 2,
        subItems: [
          {
            id: 23,
            label: 'Failed Bookings',
            link: '/failed-bookings',
            parentId: 2,
          },
          {
            id: 24,
            label: 'Hold Bookings',
            link: '/hold-bookings',
            parentId: 2,
          },
          {
            id: 10,
            label: 'Cancelled Bookings',
            link: '/cancelled-bookings',
            parentId: 9,
          },
          {
            id: 25,
            label: 'Passenger Calender',
            link: '/passenger-calendar',
            parentId: 2,
          },
        ],
      },
      {
        id: 26,
        label: 'Booking History',
        link: '/admin/confirmed-bookings',
        parentId: 2,
      },
    ],
  },
  {
    id: 3,
    label: 'Hotel',
    icon: 'uil-plane',
    link: '/hotel',
  },
  {
    id: 4,
    label: 'Holidays',
    icon: 'uil-plane',
    subItems: [
      {
        id: 21,
        label: 'Search',
        link: '/holidays',
        parentId: 2,
      },
      {
        id: 21,
        label: 'Enquiry History',
        link: '/admin/holiday/holiday-enquiry',
        parentId: 2,
      },
    ],
  },
  {
    id: 5,
    label: 'Visa',
    icon: 'uil-plane',
    subItems: [
      {
        id: 21,
        label: 'Search',
        link: '/visa',
        parentId: 2,
      },
      {
        id: 21,
        label: 'Enquiry History',
        link: '/admin/visa/visa-enquiry',
        parentId: 2,
      },
    ],
  },
  {
    id: 6,
    label: 'Eurail',
    icon: 'uil-plane',
    link: '/eurail',
  },
  {
    id: 7,
    label: 'Bus',
    icon: 'uil-plane',
    link: '/bus',
  },
  {
    id: 8,
    label: 'Cab',
    icon: 'uil-plane',
    link: '/cab',
  },
  {
    id: 9,
    label: 'Insurance',
    icon: 'uil-plane',
    link: '/insurance',
    // subItems: [
    //   {
    //     id: 10,
    //     label: 'policy2',
    //     link: '/insurance/policy2',
    //     parentId: 9,
    //   },
    //   {
    //     id: 10,
    //     label: 'policy3',
    //     link: '/insurance/policy3',
    //     parentId: 9,
    //   },
    // ],
  },
  {
    id: 10,
    label: 'Accounts',
    icon: 'uil-plane',
    subItems: [
      {
        id: 10,
        label: 'Payments',
        parentId: 9,
        subItems: [
          {
            id: 10,
            label: 'Update Payments',
            link: '/accounts/payment/update-payments',
            parentId: 9,
          },
          {
            id: 10,
            label: 'Payment History',
            link: '/payment-history',
            parentId: 9,
          },
        ],
      },
      {
        id: 10,
        label: 'Offline Ticketing',
        link: '/offline-ticketing',
        parentId: 9,
      },
      {
        id: 10,
        label: 'Credit Notes',

        parentId: 9,
        subItems: [
          {
            id: 10,
            label: 'Own',
            link: 'credit-notes/own',
            parentId: 9,
          },
          {
            id: 10,
            label: 'Agent',
            link: 'credit-notes/agent',
            parentId: 9,
          },
        ],
      },
      {
        id: 10,
        label: 'Invoices',
        link: '/invoices',
        parentId: 9,
      },
      {
        id: 10,
        label: 'Ledger and Statement',
        parentId: 9,
        subItems: [
          {
            id: 10,
            label: 'Ledger',
            link: '/ledger',
            parentId: 9,
          },
          {
            id: 10,
            label: 'Statement',
            link: '/statement',
            parentId: 9,
          },
          {
            id: 10,
            label: 'Agent Statement',
            link: '/agent-statement',
            parentId: 9,
          },
        ],
      },
      // {
      //   id: 10,
      //   label: 'Billing',
      //   link: '/billing',
      //   parentId: 9,
      // },
      {
        id: 10,
        label: 'Credit Updation',
        link: '/credit-updation',
        parentId: 9,
      },
      // {
      //   id: 10,
      //   label: 'Branch Allocation',
      //   link: 'admin/branch-allocation',
      //   parentId: 9,
      // },
    ],
  },
  {
    id: 11,
    label: 'Operations',
    icon: 'uil-plane',
    subItems: [
      // {
      //     id: 10,
      //     label: 'Queas',
      //     parentId: 9,
      //     subItems: [
      //         {
      //             id: 10,
      //             label: 'Failed Bookings',
      //             link: '/failed-bookings',
      //             parentId: 9
      //         },
      //         {
      //             id: 10,
      //             label: 'Hold Bookings',
      //             link: '/hold-bookings',
      //             parentId: 9
      //         },

      //         {
      //             id: 10,
      //             label: 'Passenger Calender',
      //             link: '/passenger-calendar',
      //             parentId: 9
      //         }
      //     ]
      // },
      // {
      //     id: 10,
      //     label: 'Booking History',
      //     link: '/admin/confirmed-bookings',
      //     parentId: 9
      // },
      {
        id: 10,
        label: 'Import PNR',
        link: '/import-ticketing',
        parentId: 9,
      },

      // {
      //     id: 10,
      //     label: 'Booking For Agency',
      //     link: '/booking-for-agency',
      //     parentId: 9
      // },
      {
        id: 10,
        label: 'Visa Queues',
        link: '/visa-queues',
        parentId: 9,
      },
      {
        id: 10,
        label: 'Holidays Queues',
        link: '/holidays-queues',
        parentId: 9,
      },
      {
        id: 10,
        label: 'Client Proxy',
        link: '/client-proxy',
        parentId: 9,
      },
    ],
  },
  {
    id: 12,
    label: 'Control Panel',
    icon: 'uil-layers',
    subItems: [
      {
        id: 10,
        label: 'Agency Master',
        link: '/admin/agency-master',
        parentId: 9,
      },
      {
        id: 10,
        label: 'Role Assignment',
        link: '/admin/settings',
        parentId: 9,
      },
      // {
      //   id: 10,
      //   label: 'Whitelabeling',
      //   link: '/whitelabeling',
      //   parentId: 9,
      // },
      {
        id: 10,
        label: 'Out-api Management',
        link: '/admin/outapi-management',
        parentId: 9,
      },
      {
        id: 10,
        label: 'Supplier',
        parentId: 9,
        subItems: [
          {
            id: 10,
            label: 'Flights Fixed Fares',
            link: '/flight-fixed-fares',
            parentId: 9,
          },
          // {
          //     id: 10,
          //     label: 'Holidays - Products',
          //     link: '/holidays-products',
          //     parentId: 9
          // },
          {
            id: 10,
            label: 'Hotels Products',
            link: '/hotels-products',
            parentId: 9,
          },
        ],
      },
      {
        id: 10,
        label: 'Markup Management',
        link: '/markup-magement',
        parentId: 9,
      },
      {
        id: 10,
        label: 'Approvals',
        parentId: 9,
        subItems: [
          {
            id: 10,
            label: 'FD Fares',
            link: '/fd-fares',
            parentId: 9,
          },
          {
            id: 10,
            label: 'Holidays',
            link: 'admin/control-panel/approvals/holidays-approvals',
            parentId: 9,
          },
          {
            id: 10,
            label: 'Hotels',
            link: '/hotels-approvals',
            parentId: 9,
          },
        ],
      },
    ],
  },
  {
    id: 13,
    label: 'Admin Panel',
    icon: 'uil-plane',
    subItems: [
      {
        id: 3,
        label: 'Holiday',
        parentId: 21,
        subItems: [
          {
            id: 23,
            label: 'Product',
            link: 'admin/holiday/product-management',
            parentId: 3,
          },
          {
            id: 3,
            label: 'Theme',
            link: 'admin/holiday/theme-management',
            parentId: 3,
          },
          {
            id: 7,
            label: 'Favourites',
            link: 'admin/holiday/favourite-management',
            parentId: 3,
          },
        ],
      },
      {
        id: 3,
        label: 'Visa',
        parentId: 21,
        subItems: [
          {
            id: 23,
            label: 'Products',
            link: 'admin/visa/visa-management',
            parentId: 3,
          },
          {
            id: 23,
            label: 'Category',
            link: 'admin/visa/category-management',
            parentId: 3,
          },
          {
            id: 3,
            label: 'Type',
            link: 'admin/visa/type-management',
            parentId: 3,
          },
          {
            id: 7,
            label: 'Favourites',
            link: 'admin/visa/favourite-management',
            parentId: 3,
          },
        ],
      },
      {
        id: 8,
        label: 'API Management',
        link: '/admin/suppliers',
        parentId: 13,
      },
      {
        id: 9,
        label: 'Communication',
        link: '/admin/notifications',
        parentId: 13,
      },
      {
        id: 10,
        label: 'General Integeration',
        link: '/admin/integration',
        parentId: 13,
      },
      {
        id: 11,
        label: 'Template',
        link: '/admin/templates',
        parentId: 13,
      },
      {
        id: 11,
        label: 'Customer Deal Manager',
        link: '/admin/dealmanagement',
        parentId: 13,
      },
      {
        id: 11,
        label: 'Supplier Deal Manager',
        link: '/admin/supplier-dealmanagement',
        parentId: 13,
      },
    ],
  },
  {
    id: 14,
    label: 'Reports',
    icon: 'uil-plane',
    subItems: [
      {
        id: 10,
        label: 'Agency Productivity',
        link: '/admin/reports/agency-productivity',
        parentId: 9,
      },
      {
        id: 10,
        label: 'Finance Team Performance',
        link: 'admin/reports/staff-performance',
        parentId: 9,
      },
      {
        id: 10,
        label: 'Sales Performance',
        link: '/admin/reports/sales-performance',
        parentId: 9,
      },
      {
        id: 10,
        label: 'Staff Productivity',
        link: '/admin/reports/staff-productivity',
        parentId: 9,
      },
      
      {
        id: 10,
        label: 'User Journey Tracker',
        link: '/reports/userjourney',
        parentId: 9,
      },
     

      // {
      //   id: 10,
      //   label: 'Product Wise Daily Sale',
      //   link: '/product-wise-daily-sale',
      //   parentId: 9,
      // },
      // {
      //   id: 10,
      //   label: 'Product Wise Monthly Sale',
      //   link: '/product-wise-monthly-sale',
      //   parentId: 9,
      // },
      // {
      //   id: 10,
      //   label: 'Agency Registrations',
      //   link: '/agency-registration',
      //   parentId: 9,
      // },
      // {
      //   id: 10,
      //   label: 'Logs - Search, Errors',
      //   link: '/logs',
      //   parentId: 9,
      // },
      // {
      //   id: 10,
      //   label: 'Travel Calendar',
      //   link: '/travel-calendar',
      //   parentId: 9,
      // },
    ],
  },
  {
    id: 15,
    label: 'Holiday List',
    icon: 'uil-plane',
    link: '/holiday-list',
  },
];
