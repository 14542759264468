<div class="pt-lg-5 pt-3 pb-lg-5 pb-3 text-center">
    <div class="container">
        <div class="h3 fw-bolder mb-lg-5 mb-3">The Figures Reveal the Truth</div>
        <div class="row ">
            <div class="col mb-4">
                <div class="bg-white rounded-3 p-4 shadow">
                    <div align="center"><img src="{{imageUrl+'assets/images/home/customers.svg'}}" height="50"></div>
                    <div class="h1 red-text text-center fw-bolder mt-3">100K+</div>
                    <p class="text-center fw-bold mb-0">Customers</p>
                </div>
            </div>
            <div class="col mb-4">
                <div class="bg-white rounded-3 p-4 shadow">
                    <div align="center"><img src="{{imageUrl+'assets/images/home/agents.svg'}}" height="50"></div>
                    <div class="h1 red-text text-center fw-bolder mt-3">12K+</div>
                    <p class="text-center fw-bold mb-0">Agents</p>
                </div>
            </div>
            <div class="col mb-4">
                <div class="bg-white rounded-3 p-4 shadow">
                    <div align="center"><img src="{{imageUrl+'assets/images/home/flight-icon.svg'}}" height="50"></div>
                    <div class="h1 red-text text-center fw-bolder mt-3">576K+</div>
                    <p class="text-center fw-bold mb-0">Flight Bookings</p>
                </div>
            </div>
            <div class="col mb-4">
                <div class="bg-white rounded-3 p-4 shadow">
                    <div align="center"><img src="{{imageUrl+'assets/images/home/hotel.svg'}}" height="50"></div>
                    <div class="h1 red-text text-center fw-bolder mt-3">10K+</div>
                    <p class="text-center fw-bold mb-0">Hotel Bookings</p>
                </div>
            </div>
            <div class="col ">
                <div class="bg-white rounded-3 p-4 shadow">
                    <div align="center"><img src="{{imageUrl+'assets/images/home/holiday.svg'}}" height="50"></div>
                    <div class="h1 red-text text-center fw-bolder mt-3">10K+</div>
                    <p class="text-center fw-bold mb-0">Holiday Bookings</p>
                </div>
            </div>
        </div>
    </div>
</div>