import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';

interface DialogData {
  type?: any,
  title?: any,
  api_url?: any,
  id?: any
}
@Component({
  selector: 'app-app-delete',
  standalone: true,
  imports: [
    MatIconModule,
    MatDialogModule,
    CommonModule
  ],
  templateUrl: './app-delete.component.html',
  styleUrl: './app-delete.component.scss'
})
export class AppDeleteComponent {
  details: any;
  isDeleting = false;
  public imageUrl = environment?.imageUrl;
  constructor(
    public dialogRef: MatDialogRef<AppDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogData) {
    this.details = {
      type: data?.type,
      title: data?.title,
      id: data?.id,
      api_url: data?.api_url,
    };
  }

  deleteConfirm() {
    this.isDeleting = true;
    this.dialogRef.close('yes');
  }
}
