<!-- <div class="container">
  <div class="section right-section" [ngClass]="{'mt-5' : item[1], 'mb-5' : item[1]}">
    <div class="card w-30  mt-4">
      <div class="card-body"> -->
<div class="h3 blue-text text-center">Welcome Back!</div>
<div class="small text-center">Login to entering the information below</div>
<div class="p-2 mt-2">
  @if(item[0]){
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <mat-tab-group (selectedTabChange)="selectedTabChange($event)">
      <mat-tab label="Account Code">
       
        <div class="mb-3 position-relative mt-2">

          <input type="input" formControlName="account_code" class="form-control" id="account_code" placeholder="Account Code"
            [ngClass]="{ 'is-invalid': f.account_code.touched && f.account_code.errors }" />
          @if(!f.account_code.errors ){
          <!-- <div class="position-absolute eye-icon cpr">
            <i class="mdi mdi-email"></i>
          </div> -->
          } @if(f.account_code.touched && f.account_code.errors){
          <div class="invalid-feedback position-relative">
            @if(f.account_code.errors.required){
            <small class="position-absolute email-validator">
              Account Code is required
            </small>

            }
            <!-- @if(f.email.errors.email){
            <small class="position-absolute email-validator">
              Invalid email address
            </small>
            } -->
          </div>
          }
        </div>

        <div class="mb-3 position-relative">
          <div class="float-end">
            <a href="/account/reset-password" class="text-muted">Forgot password?</a>
          </div>

          <input [type]="!showPassword[0] ? 'password' : 'text'" formControlName="password" class="form-control"
            id="password" [ngClass]="{
                  'is-invalid': f.password.touched && f.password.errors
                }" placeholder="Password" />
          @if(!f.password.errors){
          <div class="position-absolute eye-icon cpr">
            @if(showPassword[0]){
            <i class="fas fa-eye" (click)="togglePassword(false, 0)"></i>
            }@else{
            <i class="fas fa-eye-slash" (click)="togglePassword(true, 0)"></i>
            }
          </div>
          } @if(f.password.invalid && (f.password.touched || f.password.dirty)){
          <div class="invalid-feedback position-relative">
            @if(f.password.errors.required){
            <small class="position-absolute password-validator">
              Password is required
            </small>
            }
            @if(f.password.errors.pattern && !f.password.errors.minlength){
            <small class="position-absolute password-validator">
              Password must contain at least one letter, one number, and one special character.
            </small>
            }
            @if(f.password.errors.minlength){
            <small>Password must be at least 8 characters long.</small>
            }
          </div>
          }
        </div>
        <div class="code-message">Works Only For Agency Owners</div>
      </mat-tab>
      <mat-tab label="Email Address">
        <div class="mb-3 position-relative mt-2">

          <input type="email" formControlName="email" class="form-control" id="email" placeholder="Email Address"
            [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }" />
          @if(!f.email.errors ){
          <div class="position-absolute eye-icon cpr">
            <i class="mdi mdi-email"></i>
          </div>
          } @if(f.email.touched && f.email.errors){
          <div class="invalid-feedback position-relative">
            @if(f.email.errors.required){
            <small class="position-absolute email-validator">
              Email is required
            </small>

            }
            @if(f.email.errors.email){
            <small class="position-absolute email-validator">
              Invalid email address
            </small>

            }
          </div>
          }
        </div>

        <div class="mb-3 position-relative">
          <div class="float-end">
            <a href="/account/reset-password" class="text-muted">Forgot password?</a>
          </div>

          <input [type]="!showPassword[0] ? 'password' : 'text'" formControlName="password" class="form-control"
            id="password" [ngClass]="{
                  'is-invalid': f.password.touched && f.password.errors
                }" placeholder="Password" />
          @if(!f.password.errors){
          <div class="position-absolute eye-icon cpr">
            @if(showPassword[0]){
            <i class="fas fa-eye" (click)="togglePassword(false, 0)"></i>
            }@else{
            <i class="fas fa-eye-slash" (click)="togglePassword(true, 0)"></i>
            }
          </div>
          } @if(f.password.invalid && (f.password.touched || f.password.dirty)){
          <div class="invalid-feedback position-relative">
            @if(f.password.errors.required){
            <small class="position-absolute password-validator">
              Password is required
            </small>
            }
            @if(f.password.errors.pattern && !f.password.errors.minlength){
            <small class="position-absolute password-validator">
              Password must contain at least one letter, one number, and one special character.
            </small>
            }
            @if(f.password.errors.minlength){
            <small>Password must be at least 8 characters long.</small>
            }
          </div>
          }
        </div>
       
      </mat-tab>
      <mat-tab label="Phone number">
        <div class="mb-3 position-relative d-flex mt-2">
          <div class="col">

            <input #input class="form-control dial-code dial_code me-2" type="text" placeholder="Dial Code" matInput
              formControlName="phone_code" [matAutocomplete]="auto" (input)="filter()" (focus)="filter()"
              autocomplete="off" [ngClass]="{ 'is-invalid': f.phone_code.touched && f.phone_code.errors }">
            @if(f.phone_code.touched && f.phone_code.errors){
            <div class="invalid-feedback position-relative">
              @if(f.phone_code.errors.required){
              <small class="position-absolute password-validator">
                Dial Code *
              </small>
              }
            </div>
            }
            <mat-autocomplete requireSelection #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions" [value]="option.calling_code">
                ({{option.calling_code}}) - {{option.country_name}}
              </mat-option>
            </mat-autocomplete>

          </div>
          <div class="col">

            <input type="text" formControlName="phone" class="form-control phone_number" id="phone"
              placeholder="Phone Number" [ngClass]="{ 'is-invalid': f.phone.touched && f.phone.errors }" />
            @if(!f.phone.errors){
            <div class="position-absolute eye-icon cpr">
              <i class="uil-phone"></i>
            </div>
            }
            @if(f.phone.touched && f.phone.errors){
            <div class="invalid-feedback position-relative">
              @if(f.phone.errors.required){
              <small class="position-absolute email-validator">
                Phone Number is required
              </small>

              }
            </div>
            }
          </div>
        </div>

        <div class="mb-3 position-relative">
          <div class="float-end">
            <a href="/account/reset-password" class="text-muted">Forgot password?</a>
          </div>


          <input [type]="!showPassword[0] ? 'password' : 'text'" formControlName="password"
            class="form-control password" id="password" [ngClass]="{
                  'is-invalid': f.password.touched && f.password.errors
                }" placeholder="Password" />
          @if(!f.password.errors){
          <div class="position-absolute eye-icon cpr">
            @if(showPassword[0]){
            <i class="fas fa-eye" (click)="togglePassword(false, 0)"></i>
            }@else{
            <i class="fas fa-eye-slash" (click)="togglePassword(true, 0)"></i>
            }
          </div>
          } @if(f.password.invalid && (f.password.touched || f.password.dirty)){
          <div class="invalid-feedback position-relative">
            @if(f.password.errors.required){
            <small class="position-absolute password-validator">
              Password is required
            </small>
            }
            @if(f.password.errors.pattern && !f.password.errors.minlength){
            <small class="position-absolute password-validator">
              Password must contain at least one letter, one number, and one special character.
            </small>
            }
            @if(f.password.errors.minlength){
            <small>Password must be at least 8 characters long.</small>
            }
          </div>
          }
        </div>
      </mat-tab>
    </mat-tab-group>
    <!-- <div class="form-check">
      <input type="checkbox" [(ngModel)]="rememberMe" [ngModelOptions]="{ standalone: true }" class="form-check-input"
        id="auth-remember-check" />
      <label class="form-check-label" for="auth-remember-check">Remember me</label>
    </div> -->

    <p class="text-danger font-size-12px">{{errorMessage}}</p>

    <div class="mt-3 text-end">
      <button [disabled]="isLoading" class="btn btn-lg red-btn  w-100" type="submit">
        Log In @if(isLoading){
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        }
      </button>
    </div>

    <div class="mt-4 text-center">
      <p class="mb-0">
        Don't have an account ?
        <a routerLink="/account/signup" class="fw-medium text-primary">
          Signup now
        </a>
      </p>
    </div>
  </form>
  }@else if(item[1]){
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="mb-3 d-flex justify-content-center" formArrayName="otp">
      @for(item of otpArray.controls; let idx = $index; track item){
      <input (keyup)="onInput($event, idx)" (paste)="onPaste($event)" numbersOnly type="text" [formControlName]="idx"
        maxlength="1" [ngClass]="{ 'me-4': idx === 2, 'me-2': idx != 2 }" class="form-control otp-input"
        autocomplete="off" [id]="'otp' + idx" />
      }
    </div>
    <div class="mt-3 text-end">
      <span class="mt-3 resend">
        <!-- {{timeToShow}} -->
        <div class="col-12">
          <small class="text-danger" *ngIf="resendOtpflag === false">Resend OTP in {{timeToShow}} Seconds</small>
          <a  *ngIf="resendOtpflag === true" (click)="callResendOtp()" class="cpr otp-resend">Resend OTP</a>
        </div>
      </span>

      <button [disabled]="isLoading" (click)="verifyOtp()" class="btn btn-primary w-sm waves-effect waves-light"
        type="button">
        Verify Otp @if(isLoading){
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        }
      </button>
    </div>
  </form>
  }@else if(item[2]){
  <form [formGroup]="loginForm">
    <div class="position-relative">
      <label for="new-password">New password</label>
      <input [type]="!showPassword[1] ? 'password' : 'text'" formControlName="newPassword" class="form-control password"
        id="password" [ngClass]="{
                  'is-invalid': f.newPassword.touched && f.newPassword.errors
                }" placeholder="New password" />
      @if(!f.newPassword.errors){
      <div class="position-absolute eye-icon-change-password cpr">
        @if(showPassword[1]){
        <i class="fas fa-eye" (click)="togglePassword(false, 1)"></i>
        }@else{
        <i class="fas fa-eye-slash" (click)="togglePassword(true, 1)"></i>
        }
      </div>
      } @if(f.newPassword.invalid && (f.newPassword.touched || f.newPassword.dirty)){
      <div class="invalid-feedback position-relative">
        <!--f.newPassword.invalid && (f.newPassword.touched || f.newPassword.dirty)-->
        @if(f.newPassword.errors.required){
        <div class="position-absolute password-validator">
          Password is required
        </div>
        }
        @if(f.newPassword.errors.pattern && !f.newPassword.errors.minlength){
        <small class="position-absolute password-validator">
          Password must contain at least one letter, one number, and one special character.
        </small>
        }
        @if(f.newPassword.errors.minlength){
        <small>Password must be at least 8 characters long.</small>
        }
      </div>
      }
    </div>
    <div class="position-relative mt-2">
      <label for="new-password">Re-type new password</label>
      <input [type]="!showPassword[2] ? 'password' : 'text'" formControlName="reTypePassword" class="form-control"
        id="new-password" [ngClass]="{
                  'is-invalid':
                    f.reTypePassword.touched && f.reTypePassword.errors
                }" placeholder="Re-type new password" />
      @if(loginForm.value.reTypePassword){
      <div class="position-absolute eye-icon cpr">
        @if(showPassword[2]){
        <i class="fas fa-eye" (click)="togglePassword(false, 2)"></i>
        }@else{
        <i class="fas fa-eye-slash" (click)="togglePassword(true, 2)"></i>
        }
      </div>
      } @if(
      (loginForm.value.newPassword!=loginForm.value.reTypePassword) &&
      f.reTypePassword.valid){
      <div class="invalid-feedback position-relative">
        <div class="position-absolute password-validator">
          Passwords does not match
        </div>
      </div>
      } @if(f.reTypePassword.touched && f.reTypePassword.errors){
      <div class="invalid-feedback position-relative">
        @if(f.reTypePassword.errors.required){
        <div class="position-absolute password-validator">
          Re-type password is required
        </div>
        }
      </div>
      }
    </div>

    <div class="mt-3 text-end">
      <button [disabled]="
                  isLoading ||
                  loginForm.value.newPassword != loginForm.value.reTypePassword
                " (click)="changePassword()" class="btn btn-primary w-sm waves-effect waves-light" type="button">
        Change @if(isLoading){
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        }
      </button>
    </div>
  </form>
  }
</div>
<!-- </div>
    </div>
  </div>
</div> -->