<section class="header pt-lg-3 pt-3">
  <div class="container">
    <div class="row header1">
      <div class="col-lg-2 col-2">
        <a routerLink="/flight/flight-search">
          <img src="{{ logoUrl }}" [height]="logoHeight" />
        </a>
      </div>
      <div class="col-lg-10 col-10">
        <div
          class="top-right-nav pt-lg-4 pt-4 text-end d-flex float-right font-weight-400"
        >
          <span class="d-lg-inline-block d-none"
            ><a href="#">Support </a> <span class="devider">|</span>
            <strong matTooltip="Aval Bal: {{ avalBal }}/ OD: {{ overDraft }}"
              >Cash Bal:
              <span [ngStyle]="getColor()">{{
                avalBal ? avalBal : "∞"
              }}</span></strong
            >
            @if(showRechargeButton){
            <span class="devider">|</span>
            <a routerLink="/recharge">Recharge</a>
            }

            <span class="devider">|</span>
          </span>
          <span class="prof-link d-block" (click)="profileIcon = !profileIcon"
            >Welcome : {{ this.userName }}
            <div class="prof-pop shadow" [ngClass]="{ 'd-block': profileIcon }">
              <ul>
                <li *ngIf="!authArr.includes(userRole)">
                  <a class="d-flex justify-content-between">
                    <i class="fa-solid fa-person-military-to-person"></i> Proxy

                    <mat-slide-toggle
                      [checked]="isProxy"
                      (change)="setProxyStatus($event)"
                    ></mat-slide-toggle>
                  </a>
                </li>
                <!-- <li><a>Agency Account</a> -->
                <li>
                  <a routerLink="/profile"
                    ><i class="fa-solid fa-user-astronaut"></i> Profile</a
                  >
                </li>
                <li>
                  <a routerLink="/organization-settings"
                    ><i class="fa-regular fa-building"></i> Organization
                    Settings</a
                  >
                </li>

                <li>
                  <a (click)="logout()"
                    ><i class="fa-solid fa-arrow-right-from-bracket"></i>
                    Logout</a
                  >
                </li>
              </ul>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="inner-nav nav-duplicate">
    <div class="container">
      <div class="mob-nav-head">
        <div class="row">
          <div class="col-2">
            <div class="nav-btn" (click)="mobileIconView = !mobileIconView">
              <img
                src="{{ imageUrl + 'assets/images/icons/hamburger-menu.svg' }}"
                height="30"
              />
            </div>
          </div>
          <div class="col-10">
            <div class="m-head-nav">
              <a href="#">Support </a> <span class="devider">|</span>
              <strong>Cash Bal: {{ avalBal ? avalBal : "∞" }}</strong>
              <span class="devider">|</span>
              <a href="#">Recharge</a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mobile-menu shadow-sm"
        [ngClass]="{ 'd-block': mobileIconView }"
      >
        <div class="m-close-btn" (click)="mobileIconView = !mobileIconView">
          <img
            src="{{ imageUrl + 'assets/images/flight/close.svg' }}"
            height="20"
          />
        </div>
        <ul>
          @for (item of menuItems; track $index) {
          <li
            class="submenu-link li-list"
            [ngClass]="{ 'submenu-link': hasItems(item) }"
          >
            <a
              class="pop-up active-link"
              (click)="onMenuClick($event, $index, item)"
              [ngClass]="{
                active: activeButton == item.label,
                'submenu-link': hasItems(item)
              }"
              [routerLink]="item.link"
              role="button"
            >
              <span *ngIf="item.label === 'Flight'">
                <i class="fa-solid fa-plane-departure"></i>
              </span>
              <span *ngIf="item.label === 'Hotel'">
                <i class="fa-solid fa-bed"></i>
              </span>
              <span *ngIf="item.label === 'Holidays'">
                <i class="fa-solid fa-umbrella-beach"></i>
              </span>
              <span *ngIf="item.label === 'Visa'">
                <i class="fa-solid fa-passport"></i>
              </span>
              <span *ngIf="item.label === 'Eurail'">
                <i class="fa-solid fa-train"></i>
              </span>
              <span *ngIf="item.label === 'Bus'">
                <i class="fa-solid fa-bus"></i>
              </span>
              <span *ngIf="item.label === 'Cab'">
                <i class="fa-solid fa-taxi"></i>
              </span>
              <span *ngIf="item.label === 'Insurance'">
                <i class="fa-solid fa-user-shield"></i>
              </span>
              <span *ngIf="item.label === 'Accounts'">
                <i class="fa-solid fa-coins"></i>
              </span>
              <span *ngIf="item.label === 'Operations'">
                <i class="fa-solid fa-gears"></i>
              </span>
              <span *ngIf="item.label === 'Control Panel'">
                <i class="fa-solid fa-sliders"></i>
              </span>
              <span *ngIf="item.label === 'Admin Panel'">
                <i class="fa-solid fa-lock"></i>
              </span>
              <span *ngIf="item.label === 'Reports'">
                <i class="fa-solid fa-chart-column"></i>
              </span>

              {{ item.label }}
              <!-- {{ transformLabel(item.label) }} -->
            </a>
            @if(hasItems(item)){
            <div
              class="dropdown-menu dropdown"
              #dropdown
              [ngClass]="{
                'dropdown-mega-menu-xl px-2': item.subItems.length > 11,
                show: $index === index
              }"
              aria-labelledby="topnav-dashboard"
            >
              @for(subitem of item.subItems;track $index){
              @if(item.subItems.length < 11 && !hasItems(subitem)) {
              <a
                class="col dropdown-item side-nav-link-ref"
                [routerLink]="subitem.link"
                href="javascript: void(0);"
                routerLinkActive="active"
              >
                <span *ngIf="subitem.label === 'Search'">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </span>
                <span *ngIf="subitem.label === 'Booking History'">
                  <i class="fa-solid fa-ticket"></i>
                </span>
                <span *ngIf="subitem.label === 'Invoices'">
                  <i class="fa-solid fa-receipt"></i>
                </span>
                <span *ngIf="subitem.label === 'Credit Updation'">
                  <i class="fa-solid fa-hand-holding-dollar"></i>
                </span>
                <span *ngIf="subitem.label === 'Offline Ticketing'">
                  <i class="fa-solid fa-ticket-simple"></i>
                </span>
                <span *ngIf="subitem.label === 'Agency Productivity'">
                  <i class="fa-solid fa-magnifying-glass-chart"></i>
                </span>
                <span *ngIf="subitem.label === 'Staff Productivity'">
                  <i class="fa-solid fa-ranking-star"></i>
                </span>
                <span *ngIf="subitem.label === 'Sales Performance'">
                  <i class="fa-solid fa-magnifying-glass-location"></i>
                </span>
                <span *ngIf="subitem.label === 'User Journey Tracker'">
                  <!-- <i class="fa-regular fa-chart-bar"></i> -->
                  <i class="fa-solid fa-binoculars"></i>
                </span>
                <span *ngIf="subitem.label === 'Finance Team Performance'">
                  <!-- <i class="fa-solid fa-chart-line"></i> -->
                  <i class="fa-solid fa-magnifying-glass-dollar"></i>
                </span>
                <span *ngIf="subitem.label === 'Template'">
                  <i class="fa-solid fa-signature"></i>
                </span>
                <span *ngIf="subitem.label === 'Customer Deal Manager'">
                  <i class="fa-regular fa-handshake"></i>
                </span>
                <span *ngIf="subitem.label === 'General Integeration'">
                  <i class="fa-solid fa-code-merge"></i>
                </span>
                <span *ngIf="subitem.label === 'Communication'">
                  <i class="fa-solid fa-bullhorn"></i>
                </span>
                <span *ngIf="subitem.label === 'API Management'">
                  <i class="fa-solid fa-code"></i>
                </span>
                <!-- <span *ngIf="subitem.label === 'Whitelabeling'">
                <i class="fa-solid fa-tag"></i>
              </span> -->
                <span *ngIf="subitem.label === 'Import PNR'">
                  <i class="fa-solid fa-file-import"></i>
                </span>
                <span *ngIf="subitem.label === 'Enquiry History'">
                  <i class="fa-solid fa-ticket"></i>
                </span>
                <span *ngIf="subitem.label === 'Visa Queues'">
                  <i class="fa-solid fa-rectangle-list"></i>
                </span>
                <span *ngIf="subitem.label === 'Holidays Queues'">
                  <i class="fa-solid fa-rectangle-list"></i>
                </span>
                <span *ngIf="subitem.label === 'Agency Master'">
                  <i class="fa-solid fa-sitemap"></i>
                </span>
                <span *ngIf="subitem.label === 'Role Assignment'">
                  <i class="fa-solid fa-user-plus"></i>
                </span>
                <span *ngIf="subitem.label === 'Out-api Management'">
                  <i class="fa-solid fa-code-branch"></i>
                </span>
                <span *ngIf="subitem.label === 'Supplier Deal Manager'">
                  <i class="fa-solid fa-handshake-angle"></i>
                </span>
                <span *ngIf="subitem.label === 'Client Proxy'">
                  <i class="fa-solid fa-people-arrows"></i>
                </span>

                {{ subitem.label }}
                <!-- {{ transformLabel(subitem.label) }} --> </a
              >} @if(item.subItems.length > 11){
              <div>
                @if($index % 3 == 0){
                <div class="row">
                  <div class="col-lg-4">
                    <a
                      class="dropdown-item side-nav-link-ref"
                      routerLinkActive="active"
                      [routerLink]="subitem.link"
                    >
                      <!-- <span *ngIf="item.subItems[$index].label === 'Passenger Calendar'">
                      <i class="fa-solid fa-calendar-days"></i>
                    </span> -->

                      {{ item.subItems[$index].label }}
                      <!-- {{ transformLabel(item.subItems[$index].label) }} -->
                    </a>
                  </div>
                  <div class="col-lg-4">
                    <a
                      class="dropdown-item side-nav-link-ref"
                      routerLinkActive="active"
                      [routerLink]="item.subItems[$index + 1]?.link"
                    >
                      {{ item.subItems[$index + 1]?.label }}
                      <!-- {{ transformLabel(item.subItems[$index + 1]?.label) }} -->
                    </a>
                  </div>
                  <div class="col-lg-4">
                    <a
                      class="dropdown-item side-nav-link-ref"
                      routerLinkActive="active"
                      [routerLink]="item.subItems[$index + 2]?.link"
                    >
                      {{ item.subItems[$index + 2]?.label }}
                      <!-- {{ transformLabel(item.subItems[$index + 2]?.label) }} -->
                    </a>
                  </div>
                </div>
                }
              </div>
              } @if(hasItems(subitem)){
              <div class="dropdown">
                <a
                  class="dropdown-item"
                  href="javascript: void(0);"
                  (click)="onSubMenu($event, $index)"
                >
                  <span *ngIf="subitem.label === 'Queues'">
                    <i class="fa-solid fa-layer-group"></i>
                  </span>
                  <span *ngIf="subitem.label === 'Payments'">
                    <i class="fa-solid fa-money-check-dollar"></i>
                  </span>
                  <span *ngIf="subitem.label === 'Credit Notes'">
                    <i class="fa-solid fa-credit-card"></i>
                  </span>
                  <span *ngIf="subitem.label === 'Ledger and Statement'">
                    <i class="fa-solid fa-file-invoice-dollar"></i>
                  </span>
                  <span *ngIf="subitem.label === 'Holiday'">
                    <i class="fa-solid fa-umbrella-beach"></i>
                  </span>
                  <span *ngIf="subitem.label === 'Visa'">
                    <i class="fa-solid fa-passport"></i>
                  </span>
                  <span *ngIf="subitem.label === 'Supplier'">
                    <i class="fa-solid fa-users-rays"></i>
                  </span>
                  <span *ngIf="subitem.label === 'Approvals'">
                    <i class="fa-solid fa-person-circle-check"></i>
                  </span>

                  {{ subitem.label }}
                  <!-- {{ transformLabel(subitem.label) }} -->
                  <div class="arrow-down"></div>
                </a>
                <div
                  class="dropdown-menu"
                  [ngClass]="{ show: $index === subIndex }"
                >
                
                  <ng-template
                    ngFor
                    let-subSubitem
                    [ngForOf]="subitem.subItems"
                    
                  >
                    @if(!hasItems(subSubitem) && subSubitem?.subItems ==
                    undefined ){
                    <!-- <ng-container *ngIf="!hasItems(subSubitem)"> -->
                    <a
                      class="dropdown-item side-nav-link-ref"
                      [routerLink]="subSubitem.link"
                      routerLinkActive="active"
                      
                    >
                      <span *ngIf="subSubitem.label === 'Passenger Calender'">
                        <i class="fa-solid fa-calendar-days"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Cancelled Bookings'">
                        <i class="fa-solid fa-plane-slash"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Hold Bookings'">
                        <i class="fa-solid fa-plane-circle-exclamation"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Failed Bookings'">
                        <i class="fa-solid fa-plane-circle-xmark"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Update Payments'">
                        <i class="fa-regular fa-money-bill-1"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Payment History'">
                        <i class="fa-solid fa-money-bill-transfer"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Statement'">
                        <i class="fa-regular fa-id-card"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Ledger'">
                        <i class="fa-solid fa-book"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Agent Statement'">
                        <i class="fa-solid fa-user-clock"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Agent'">
                        <i class="fa-solid fa-user-tie"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Owner'">
                        <i class="fa-solid fa-user-gear"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Favourites'">
                        <i class="fa-solid fa-star"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Hotels'">
                        <i class="fa-solid fa-building-circle-check"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Holidays'">
                        <i class="fa-solid fa-heart-circle-check"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Own'">
                        <i class="fa-solid fa-user-gear"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Flights Fixed Fares'">
                        <i class="fa-solid fa-plane-departure"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Hotels Products'">
                        <i class="fa-solid fa-bed"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'FD Fares'">
                        <i class="fa-solid fa-plane-circle-check"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Product'">
                        <i class="fa-solid fa-wand-magic-sparkles"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Theme'">
                        <i class="fa-solid fa-icons"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Products'">
                        <i class="fa-solid fa-wand-magic-sparkles"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Category'">
                        <i class="fa-solid fa-icons"></i>
                      </span>
                      <span *ngIf="subSubitem.label === 'Type'">
                        <i class="fa-solid fa-tags"></i>
                      </span>

                      {{ subSubitem.label }}
                      <!-- {{ transformLabel(subSubitem.label) }} -->
                    </a>
                    <!-- </ng-container> -->
                    }
                    <ng-container
                      *ngIf="hasItems(subSubitem) && subSubitem.subItems"
                    >
                      <div class="dropdown d-flex">
                        <a
                          class="dropdown-item side-nav-link-ref"
                          [routerLink]="subSubitem.link"
                          routerLinkActive="active"
                          (click)="onSubMenuClick($event)"
                        >
                          {{ subSubitem.label }}
                          <!-- {{ transformLabel(subSubitem.label) }} -->
                        </a>
                        <div class="arrow-down"></div>
                      </div>
                      <div
                        class="dropdown-menu side side-nav-link-ref"
                        #subMenu
                      >
                        <ng-template
                          ngFor
                          let-subSubitems
                          [ngForOf]="subSubitem.subItems"
                        >
                          <a
                            class="dropdown-item side-nav-link-ref"
                            [routerLink]="subSubitems.link"
                            routerLinkActive="active"
                          >
                            {{ subSubitems.label }}
                            <!-- {{ transformLabel(subSubitems.label) }} -->
                          </a>
                        </ng-template>
                      </div>
                    </ng-container>
                  </ng-template>
                </div>
              </div>
              } }
            </div>
            }
          </li>
          }
        </ul>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</section>
