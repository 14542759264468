<!-- header ends here-->
<div class="contact-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 d-flex flex-column justify-content-center">
                <h1 class="fb-h1">Contact Us</h1>
                <p class="lead">Partner with us for seamless travel solutions! Access exclusive B2B deals, simplify
                    bookings, and grow your travel business with our advanced travel portal. Collaborate with us to
                    deliver exceptional experiences. Whether you have queries about our services or need personalized
                    support, We're here to assist. Connect today!</p>
            </div>
            <div class="col-lg-5">
                <img src="{{imageUrl+'assets/images/contact/contact-img.svg'}}" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<!-- Inner container starts here-->
<section class="inner-con pt-lg-4 pt-3 pb-3">
    <div class="container">
        <div class="h3">Global Locations</div>
        <hr>
        <div class="row">
            <div class="col-lg-3 mb-4">
                <div class="rounded-3 shadow p-3">
                    <div class="rounded overflow-hidden contact-img-con c-red-bg"
                        style="background-image:url('{{imageUrl}}assets/images/contact/liberty.svg');"></div>
                    <div class="pt-3">
                        <div class="h5 fw-bold">USA</div>
                        <p class="mb-0 mb-0">
                            <strong>B2B Travel Agency</strong><br>
                            800 W Airport FWY, Suite # 1100, Irving, TX-75062
                        </p>
                        <hr>
                        <p class="mb-0"> <span class="d-inline-block me-2"><i class="fa fa-phone"
                                    aria-hidden="true"></i></span> <a href="tel:+1(972) 456-9999">+1(972)
                                456-9999</a><br>
                            <span class="d-inline-block me-2"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                            <a
                                href="mailto:sales.india@{{clientAbbr}}travelagency.com">sales.india&#64;{{clientAbbr}}travelagency.com</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mb-4">
                <div class="rounded-3 shadow p-3">
                    <div class="rounded overflow-hidden contact-img-con c-blue-bg"
                        style="background-image:url('{{imageUrl}}assets/images/contact/canada.svg');"></div>
                    <div class="pt-3">
                        <div class="h5 fw-bold">Canada</div>
                        <p class="mb-0">
                            <strong>B2B Travel Agency</strong><br>
                            12907 - 97 st NW, Suite #202, Edmonton, Alberta - T5E4C2<br>
                        </p>
                        <hr>
                        <p class="mb-0">
                            <span class="d-inline-block me-2"><i class="fa fa-phone" aria-hidden="true"></i></span> <a
                                href="tel:+1 (888) 379-1003">+1 (888) 379-1003</a><br>
                            <span class="d-inline-block me-2"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                            <a
                                href="mailto:sales.india@{{clientAbbr}}travelagency.com">sales.india&#64;{{clientAbbr}}travelagency.com</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mb-4">
                <div class="shadow rounded-3 p-3">
                    <div class="rounded overflow-hidden contact-img-con c-red-bg"
                        style="background-image:url('{{imageUrl}}assets/images/contact/big-ben.svg');"></div>
                    <div class="pt-3">
                        <div class="h5 fw-bold">UK</div>
                        <p class="mb-0">
                            <strong>B2B Travel Agency</strong><br> 12 Crown St, Ipswich IP1 3LD, United Kingdom<br>
                        </p>
                        <hr>
                        <p class="mb-0">
                            <span class="d-inline-block me-2"><i class="fa fa-phone" aria-hidden="true"></i></span> <a
                                href="tel:+44 2045930033">+44 2045930033</a><br>
                            <span class="d-inline-block me-2"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                            <a
                                href="mailto:sales.india@{{clientAbbr}}travelagency.com">sales.india&#64;{{clientAbbr}}travelagency.com</a>
                            <!-- <a href="mailto:sales.india@b2btravelagency.com" style="font-size:11px;">sales.india@b2btravelagency.com</a> -->
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mb-4">
                <div class="shadow rounded-3 p-3">
                    <div class="rounded overflow-hidden contact-img-con c-blue-bg"
                        style="background-image:url('{{imageUrl}}assets/images/contact/burj-al-arab.svg');"></div>
                    <div class="pt-3">
                        <div class="h5 fw-bold">UAE</div>
                        <p class="mb-0">
                            <strong>B2B Travel Agency</strong><br> Entrance 5, BELHOUL Group Building - Office No. 316
                            <br>
                        </p>
                        <hr>
                        <p class="mb-0">
                            <span class="d-inline-block me-2"><i class="fa fa-phone" aria-hidden="true"></i></span> <a
                                href="tel:+971 50 376 1135">+971 50 376 1135</a><br>
                            <span class="d-inline-block me-2"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                            <a
                                href="mailto:sales.india@{{clientAbbr}}travelagency.com">sales.india&#64;{{clientAbbr}}travelagency.com</a>
                            <!-- <a href="mailto:sales.india@b2btravelagency.com" style="font-size:11px;">sales.india@b2btravelagency.com</a> -->
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="h3">India Offices</div>
        <hr>
        <div class="row">
            <div class="col-lg-4 mb-4">
                <div class="rounded-3 shadow p-3">
                    <div class="rounded overflow-hidden contact-img-con c-red-bg"
                        style="background-image:url('{{imageUrl}}assets/images/contact/office-mumbai.svg');"></div>
                    <div class="pt-3">
                        <div class="h5 fw-bold">Mumbai Office</div>
                        <p class="mb-0 mb-0">
                            <strong>B2B Travel Agency India Pvt Ltd</strong><br>
                            A-803 BSEL TECH PARKSECTOR-30A, PLOT NO 39/5,39/5A Opp Vashi Railway Station, Navi Mumbai,
                            400703, Maharashtra, India
                        </p>
                        <hr>
                        <p class="mb-0"> <span class="d-inline-block me-2"><i class="fa fa-phone"
                                    aria-hidden="true"></i></span> <a href="tel:">022-6969 3333</a><br>
                            <span class="d-inline-block me-2"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                            <a
                                href="mailto:sales.india@{{clientAbbr}}travelagency.com">sales.india&#64;{{clientAbbr}}travelagency.com</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-4">
                <div class="rounded-3 shadow p-3">
                    <div class="rounded overflow-hidden contact-img-con c-blue-bg"
                        style="background-image:url('{{imageUrl}}assets/images/contact/office-delhi.svg');"></div>
                    <div class="pt-3">
                        <div class="h5 fw-bold">Delhi Office</div>
                        <p class="mb-0">
                            <strong>B2B Travel Agency India Pvt Ltd</strong><br>
                            202-203, 2nd Floor, BMC House,<br>
                            N-1, Connaught Place,
                            New Delhi 110001<br>
                        </p>
                        <hr>
                        <p class="mb-0">
                            <span class="d-inline-block me-2"><i class="fa fa-phone" aria-hidden="true"></i></span> <a
                                href="tel:01149499800">011 4949 9800</a><br>
                            <span class="d-inline-block me-2"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                            <a
                                href="mailto:sales.india@{{clientAbbr}}travelagency.com">sales.india&#64;{{clientAbbr}}travelagency.com</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-4">
                <div class="shadow rounded-3 p-3">
                    <div class="rounded overflow-hidden contact-img-con c-red-bg"
                        style="background-image:url('{{imageUrl}}assets/images/contact/office-kolkata.svg');"></div>
                    <div class="pt-3">
                        <div class="h5 fw-bold">Kolkata Office</div>
                        <p class="mb-0">
                            <strong>B2B Travel Agency India Pvt Ltd</strong><br> Old Court House Corner,
                            Tobacco House 3rd Floor, Room No.308,
                            Kolkata-700001<br>
                        </p>
                        <hr>
                        <p class="mb-0">
                            <span class="d-inline-block me-2"><i class="fa fa-phone" aria-hidden="true"></i></span> <a
                                href="tel:+919007570734">+91 90075 70734</a><br>
                            <span class="d-inline-block me-2"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                            <a
                                href="mailto:sales.india@{{clientAbbr}}travelagency.com">sales.india&#64;{{clientAbbr}}travelagency.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4">
                <div class="shadow rounded-3 p-3">
                    <div class="rounded overflow-hidden contact-img-con c-blue-bg"
                        style="background-image:url('{{imageUrl}}assets/images/contact/office-cochin.svg');"></div>
                    <div class="pt-3">
                        <div class="h5 fw-bold">Cochin Office</div>
                        <p class="mb-0">
                            2nd Floor, Thrive Space Solutions,<br>
                            Principal Place Of Business,<br>
                            17/1684, Chittethukara,<br>
                            Kakkanad, Ernakulam - 682 037, Kerala.<br></p>
                        <hr>
                        <p class="mb-0">
                            <span class="d-inline-block me-2"><i class="fa fa-phone" aria-hidden="true"></i></span> <a
                                href="tel:+917290066347 ">+91 72900 66347 </a><br>
                            <span class="d-inline-block me-2"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                            <a
                                href="mailto:sales.india@{{clientAbbr}}travelagency.com">sales.india&#64;{{clientAbbr}}travelagency.com</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="shadow p-3 rounded-3">
                    <div class="rounded overflow-hidden contact-img-con c-red-bg"
                        style="background-image:url('{{imageUrl}}assets/images/contact/office-Jalandhar.svg');"></div>
                    <div class="pt-3">
                        <div class="h5 fw-bold">Jalandhar Office</div>
                        <p class="mb-0">
                            Office no.- 16,3rd floor,<br>
                            Silver Crown Plaza ( Main Building), PPR Mall,<br>
                            Near Gole Market, JALANDHAR 144001<br></p>
                        <hr>
                        <p class="mb-0">
                            <span class="d-inline-block me-2"><i class="fa fa-phone" aria-hidden="true"></i></span> <a
                                href="tel:01814099800">0181-4099800</a><br>
                            <span class="d-inline-block me-2"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                            <a
                                href="mailto:sales.india@{{clientAbbr}}travelagency.com">sales.india&#64;{{clientAbbr}}travelagency.com</a>
                        </p>
                    </div>
                </div>
            </div>

        </div>


    </div>
</section>
<!-- Inner container ends here-->