import { Injectable } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountdownService {
  private subscription: Subscription;
  public countdownTime: number;
  public displayTime: string;

  constructor() {
    this.countdownTime = 0; // Initialize countdown time
    this.displayTime = '0:00'; // Initialize display time
  }

  startCountdown(seconds: number) {
    this.countdownTime = seconds;
    this.updateDisplayTime();
    
    this.subscription = interval(1000).subscribe(() => {
      if (this.countdownTime > 0) {
        this.countdownTime--;
        this.updateDisplayTime();
      } else {
        this.stopCountdown(); // Stop the countdown
      }
    });
  }

  stopCountdown() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getRemainingTime(): string {
    return this.displayTime;
  }

  private updateDisplayTime(): void {
    const minutes = Math.floor(this.countdownTime / 60);
    const seconds = this.countdownTime % 60;
    this.displayTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }
}